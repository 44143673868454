<template>
  <b-container fluid>
    <b-container>
      <div class="col-md-12">
        <h1>Błąd 404 - Strona, której szukasz nie istnieje.... :(</h1>
        <b-link :to="{ name: 'home'}">Przejdź na stronę główną</b-link>
      </div>
    </b-container>
  </b-container>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>
